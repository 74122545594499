<template>
  <div>
    <meta
      http-equiv="content-type"
      content="application/vnd.ms-excel; charset=UTF-8"
    />
    <template v-if="loading"> Cargando... </template>
    <template v-else>
      <template v-if="sectionEvaluations.length == 0">
        No hay evaluaciones planificadas.
      </template>
      <template v-else>
        <b-tabs
          content-class="mt-1"
          class="w-100"
          v-model="tab_index"
          pills
          card
          vertical
        >
          <b-tab title="Sumativas">
            <div v-if="filterEvaluation.length == 0">
              No existen evaluaciones Sumativas
            </div>
            <template v-else>
              <div class="row mt-1">
                <b-form-group
                  label="Evaluaciones"
                  label-cols="0"
                  label-cols-sm="2"
                  label-cols-md="3"
                  label-cols-lg="4"
                  class="my-0 py-0 w-100"
                >
                  <b-form-select
                    v-model="selected_study_unit_id"
                    value-field="id"
                    text-field="name"
                    size="sm"
                    ><template #first>
                      <b-form-select-option :value="null"
                        >Seleccione una de las opciones</b-form-select-option
                      ></template
                    >
                    <b-form-select-option :value="-1"
                      >Mostrar Todas Las Evaluaciones Por unidad de
                      estudio.</b-form-select-option
                    >
                    <b-form-select-option :value="1"
                      >Mostrar Notas Ponderadas por unidad de
                      estudio.</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="row" v-if="selected_study_unit_id">
                <div class="separator mt-1 mb-3">
                  <button
                    @click="reload"
                    class="btn-reload"
                    v-b-tooltip.v-secondary="'Recargar'"
                  >
                    <b-icon icon="arrow-clockwise" class="reload-icon">
                    </b-icon>
                  </button>
                </div>
                <template v-if="selected_study_unit_id == -1">
                  <table class="qualifications-table">
                    <thead>
                      <tr>
                        <th class="th-qualifications" rowspan="2">Nombre</th>
                        <th class="th-qualifications" rowspan="2">Apellido</th>
                        <template v-for="item in study_unit">
                          <th
                            v-if="countEvaluationPerStudyUnit(item) > 0"
                            class="th-qualifications"
                            v-bind:key="item.id"
                            v-b-tooltip.v-secondary="
                              item.name +
                              '   Ponderación: ' +
                              item.weighing +
                              '%'
                            "
                            :colspan="colspanRow(item)"
                          >
                            {{ item.name }} / {{ item.weighing }}%
                          </th>
                        </template>
                        <th class="th-qualifications" rowspan="2">
                          Nota Final
                        </th>
                      </tr>
                      <tr>
                        <th
                          class="th-qualifications"
                          v-for="evaluation in filterEvaluation"
                          v-bind:key="evaluation.id"
                          v-b-tooltip.v-secondary="
                            'Tipo: ' +
                            getEvaluationInstrumentType(
                              evaluation.evaluation_instrument
                            ) +
                            '   Ponderación: ' +
                            evaluation.weighing +
                            '%'
                          "
                        >
                          {{ evaluation.name }} / {{ evaluation.weighing }}%
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="tr-qualifications"
                        v-for="student in filteredStudents"
                        v-bind:key="student.id"
                      >
                        <td class="td-qualifications">
                          {{ student.first_name }}
                        </td>
                        <td class="td-qualifications">
                          {{ student.last_name }}
                        </td>
                        <td
                          class="td-qualifications"
                          v-for="evaluation in filterEvaluation"
                          v-bind:key="evaluation.id"
                          v-b-tooltip.v-secondary="
                            'Tipo: ' +
                            getEvaluationInstrumentType(
                              evaluation.evaluation_instrument
                            ) +
                            '   Ponderación: ' +
                            evaluation.weighing +
                            '%'
                          "
                        >
                          <UserQualification
                            :user_id="student.id"
                            :evaluation_id="evaluation.id"
                            :allows_crud="allows_crud"
                            :evaluation_score_list="evaluation_score"
                          ></UserQualification>
                        </td>
                        <td class="td-qualifications td-final-score">
                          <div
                            :class="{
                              'red-score': finalScore(student) < 4,
                              'yellow-score':
                                finalScore(student) >= 4 &&
                                finalScore(student) < 5.5,
                              'green-score': finalScore(student) >= 5.5,
                            }"
                          >
                            {{ finalScore(student) | Round }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
                <template v-if="selected_study_unit_id == 1">
                  <table class="qualifications-table">
                    <thead>
                      <tr>
                        <th class="th-qualifications">Nombre</th>
                        <th class="th-qualifications">Apellido</th>
                        <th
                          class="th-qualifications"
                          v-for="item in study_unit"
                          v-bind:key="item.id"
                          v-b-tooltip.v-secondary="
                            item.name + '   Ponderación: ' + item.weighing + '%'
                          "
                        >
                          {{ item.name }} / {{ item.weighing }}%
                        </th>
                        <th class="th-qualifications" rowspan="2">
                          Nota Final
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="tr-qualifications"
                        v-for="student in filteredStudents"
                        v-bind:key="student.id"
                      >
                        <td class="td-qualifications">
                          {{ student.first_name }}
                        </td>
                        <td class="td-qualifications">
                          {{ student.last_name }}
                        </td>
                        <td
                          class="td-qualifications"
                          v-for="item in study_unit"
                          v-bind:key="item.id"
                          v-b-tooltip.v-secondary="
                            item.name + '   Ponderación: ' + item.weighing + '%'
                          "
                        >
                          <div
                            v-if="countEvaluationPerStudyUnit(item) > 0"
                            :class="{
                              'red-score': scorePerStudyUnit(item, student) < 4,
                              'yellow-score':
                                scorePerStudyUnit(item, student) >= 4 &&
                                scorePerStudyUnit(item, student) < 5.5,
                              'green-score':
                                scorePerStudyUnit(item, student) >= 5.5,
                            }"
                          >
                            {{ scorePerStudyUnit(item, student) | Round }}
                          </div>
                          <div v-else>No cuenta con Evaluaciones</div>
                        </td>
                        <td class="td-qualifications td-final-score">
                          <div
                            :class="{
                              'red-score': finalScore(student) < 4,
                              'yellow-score':
                                finalScore(student) >= 4 &&
                                finalScore(student) < 5.5,
                              'green-score': finalScore(student) >= 5.5,
                            }"
                          >
                            {{ finalScore(student) | Round }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
              </div>
              <template v-else
                ><div class="w-100">
                  Debe seleccionar una de las opciones disponibles.
                </div></template
              >
            </template>
          </b-tab>
          <b-tab title="Formativas y Diagnósticas">
            <div v-if="filterEvaluation.length == 0">
              No existen evaluaciones Formativas o Diagnóstica.
            </div>
            <template v-else>
              <div class="separator mt-1 mb-3">
                <button
                  @click="reload"
                  class="btn-reload"
                  v-b-tooltip.v-secondary="'Recargar'"
                >
                  <b-icon icon="arrow-clockwise" class="reload-icon"> </b-icon>
                </button>
              </div>
              <table class="qualifications-table">
                <thead>
                  <tr>
                    <th class="th-qualifications" rowspan="2">Nombre</th>
                    <th class="th-qualifications" rowspan="2">Apellido</th>
                    <template v-for="item in study_unit">
                      <th
                        v-if="countEvaluationPerStudyUnit(item) > 0"
                        class="th-qualifications"
                        v-bind:key="item.id"
                        v-b-tooltip.v-secondary="
                          item.name + '   Ponderación: ' + item.weighing + '%'
                        "
                        :colspan="colspanRowNonSumative(item)"
                      >
                        {{ item.name }}
                      </th>
                    </template>
                    <!-- <th class="th-qualifications" rowspan="2">Nota Final</th> -->
                  </tr>
                  <tr>
                    <th
                      class="th-qualifications"
                      v-for="evaluation in filterEvaluation"
                      v-bind:key="evaluation.id"
                      v-b-tooltip.v-secondary="
                        'Tipo: ' + getEvalautionType(evaluation.type)
                      "
                    >
                      {{ evaluation.name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="tr-qualifications"
                    v-for="student in filteredStudents"
                    v-bind:key="student.id"
                  >
                    <td class="td-qualifications">
                      {{ student.first_name }}
                    </td>
                    <td class="td-qualifications">
                      {{ student.last_name }}
                    </td>
                    <td
                      class="td-qualifications"
                      v-for="evaluation in filterEvaluation"
                      v-bind:key="evaluation.id"
                      v-b-tooltip.v-secondary="
                        'Tipo: ' + getEvalautionType(evaluation.type)
                      "
                    >
                      <UserQualification
                        :user_id="student.id"
                        :evaluation_id="evaluation.id"
                        :allows_crud="allows_crud"
                        :evaluation_score_list="evaluation_score"
                      ></UserQualification>
                    </td>
                    <!-- <td class="td-qualifications td-final-score">
                    <div
                      :class="{
                        'red-score': finalScore(student) < 4,
                        'yellow-score':
                          finalScore(student) >= 4 && finalScore(student) < 5.5,
                        'green-score': finalScore(student) >= 5.5,
                      }"
                    >
                      {{ finalScore(student) | Round }}
                    </div>
                  </td> -->
                  </tr>
                </tbody>
              </table>
            </template>
          </b-tab>
        </b-tabs>
      </template>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "Qualifications",
  components: {
    UserQualification: () => import("./UserQualification"),
  },
  props: {
    Section: {
      type: Object,
      required: true,
    },
    evaluation_id: {
      type: Number,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_study_unit_id: null,
      tab_index: 0,
      evaluation_score: null,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      evaluations: names.EVALUATIONS,
      questions: names.QUESTIONS,
      rubrics: names.OBSERVATION_INSTRUMENTS,
      users: names.USERS,
      instrumentTypes: names.EVALUATION_INSTRUMENTS,
      study_unit: names.STUDY_UNITS,
    }),
    findEvaluation() {
      let evaluation = this.evaluations.find(
        (evaluation) => evaluation.id == this.evaluation_id
      );
      return evaluation ? evaluation : null;
    },
    filterEvaluation() {
      if (this.tab_index == 1) {
        let list = this.evaluations.filter(
          (x) => x.section == this.Section.id && (x.type == 1 || x.type == 2)
        );
        return list.sort(function (a, b) {
          if (a.study_unit > b.study_unit) return 1;
          if (a.study_unit < b.study_unit) return -1;
          if (a.end_date > b.end_date) return 1;
          if (a.end_date < b.end_date) return -1;
          return 0;
        });
      }
      //Para Mostrar Todas Las Notas y type == 3 es para las evaluaciones Sumativas
      if (this.selected_study_unit_id == -1) {
        let list = this.evaluations.filter(
          (x) => x.section == this.Section.id && x.type == 3
        );
        return list.sort(function (a, b) {
          if (a.study_unit > b.study_unit) return 1;
          if (a.study_unit < b.study_unit) return -1;
          if (a.end_date > b.end_date) return 1;
          if (a.end_date < b.end_date) return -1;
          return 0;
        });
      }
      //Por Unidad y type == 3 es para las evaluaciones Sumativas
      let list = this.evaluations.filter(
        (x) => x.section == this.Section.id && x.type == 3
      );
      return list.sort(function (a, b) {
        if (a.study_unit > b.study_unit) return 1;
        if (a.study_unit < b.study_unit) return -1;
        if (a.end_date > b.end_date) return 1;
        if (a.end_date < b.end_date) return -1;
        return 0;
      });
    },

    filteredStudents() {
      let list = [];
      if (this.tab_index == 1) {
        this.Section.students.forEach((user_id) => {
          const user = this.users.find((x) => x.id == user_id);
          if (user) {
            list.push(user);
          }
        });
        return list;
      }
      if (!this.selected_study_unit_id) return [];
      this.Section.students.forEach((user_id) => {
        const user = this.users.find((x) => x.id == user_id);
        if (user) {
          list.push(user);
        }
      });
      return list;
    },
    sectionEvaluations() {
      let list = this.evaluations.filter((x) => x.section == this.Section.id);
      return list;
    },
  },
  methods: {
    reload() {
      let ScorePerStudent = [];
      this.Section.students.forEach((user_id) => {
        this.$restful
          .Get(`/evaluation/evaluation-score/?user=${user_id}`)
          .then((response) => {
            response.forEach((x) => ScorePerStudent.push(x));
          });
      });
      this.evaluation_score = ScorePerStudent;
      //   this.$restful.Get(`/evaluation/evaluation-score/`).then((response) => {
      //     this.evaluation_score = response;
      //   });
    },
    countEvaluationPerStudyUnit(study_unit) {
      if (this.tab_index == 1) {
        return this.evaluations.filter(
          (x) =>
            x.section == this.Section.id &&
            (x.type == 1 || x.type == 2) &&
            x.study_unit == study_unit.id
        ).length;
      }
      return this.evaluations.filter(
        (x) =>
          x.section == this.Section.id &&
          x.type == 3 &&
          x.study_unit == study_unit.id
      ).length;
    },
    scorePerStudyUnit(study_unit, student) {
      let score = 0;
      this.evaluations.forEach((x) => {
        if (
          x.section == this.Section.id &&
          x.type == 3 &&
          x.study_unit == study_unit.id
        ) {
          if (this.evaluation_score) {
            // type == 3 es para las evaluaciones Sumativas
            const result = this.evaluation_score.find(
              (a) => a.evaluation == x.id && a.student == student.id
            );
            if (result) {
              if (result.automated_score) {
                score += result.automated_score * (x.weighing / 100);
              } else if (result.score) {
                score += result.score * (x.weighing / 100);
              }
            }
          }
        }
      });
      return score;
    },
    colspanRow(study_unit) {
      if (!study_unit) return 0;
      return this.evaluations.filter(
        (x) =>
          x.study_unit == study_unit.id &&
          x.type == 3 &&
          x.section == this.Section.id
      ).length;
    },
    colspanRowNonSumative(study_unit) {
      if (!study_unit) return 0;
      return this.evaluations.filter(
        (x) =>
          x.study_unit == study_unit.id &&
          (x.type == 1 || x.type == 2) &&
          x.section == this.Section.id
      ).length;
    },
    finalScore(student) {
      let finalScore = 0;
      if (
        this.selected_study_unit_id == -1 ||
        this.selected_study_unit_id == 1
      ) {
        this.study_unit.forEach((u) => {
          let score = 0;
          this.evaluations.forEach((x) => {
            if (
              x.section == this.Section.id &&
              x.type == 3 &&
              x.study_unit == u.id
            ) {
              if (this.evaluation_score) {
                // type == 3 es para las evaluaciones Sumativas
                const result = this.evaluation_score.find(
                  (a) => a.evaluation == x.id && a.student == student.id
                );
                if (result) {
                  if (result.automated_score) {
                    score += result.automated_score * (x.weighing / 100);
                  } else if (result.score) {
                    score += result.score * (x.weighing / 100);
                  }
                }
              }
            }
          });
          finalScore += score * (u.weighing / 100);
        });
      }
      return finalScore;
    },
    getEvalautionType(type) {
      if (type == 1) return "Diagnóstica";
      if (type == 2) return "Formativa";
      return;
    },
    getEvaluationInstrumentType(id) {
      const type = this.instrumentTypes.find((x) => x.id == id);
      if (type) return type.value;
      return "-";
    },
  },
  watch: {
    evaluation_id() {
      this.selected_study_unit_id = this.evaluation_id
        ? this.findEvaluation.type == 3
          ? -1
          : null
        : null;
      this.tab_index = this.findEvaluation
        ? this.findEvaluation.type == 3
          ? 0
          : 1
        : 0;
    },
  },
  created() {
    this.loading = true;
    let ScorePerStudent = [];
    this.Section.students.forEach((user_id) => {
      this.$store.dispatch(names.FETCH_USER, user_id);
      this.$restful
        .Get(`/evaluation/evaluation-score/?user=${user_id}`)
        .then((response) => {
          response.forEach((x) => ScorePerStudent.push(x));
        });
    });
    this.$store
      .dispatch(
        names.FETCH_EGRESS_PROFILE_MATTER,
        this.Section.egress_profile_matter
      )
      .then((matter) => {
        this.$store
          .dispatch(names.FETCH_STUDY_UNITS, matter.matter)
          .then(() => {
            this.loading = false;
          });
      });
    this.$store
      .dispatch(names.FETCH_EVALUATIONS_BY_SECTION, this.Section.id)
      .then(() => {
        this.loading = false;
        this.selected_study_unit_id = this.evaluation_id
          ? this.findEvaluation
            ? this.findEvaluation.type == 3
              ? -1
              : null
            : null
          : null;
        this.tab_index = this.findEvaluation
          ? this.findEvaluation.type == 3
            ? 0
            : 1
          : 0;
      });
    this.evaluation_score = ScorePerStudent;
  },
};
</script>

<style scoped>
.qualifications-table {
  width: 100%;
  border: 0.5px solid rgb(235, 235, 235);
}
.th-qualifications {
  background-color: var(--primary-color) !important;
  padding: 8px 16px;
  border: 0.7px solid #666;
  font-size: var(--secondary-font-size);
}
/* .th-qualifications:first-child{
  border-top-left-radius: 10px;
}
.th-qualifications:last-child{
  border-top-right-radius: 10px;
} */
.tr-qualifications {
  margin: 5px 0 !important;
  font-size: var(--secondary-font-size);
}
.tr-qualifications:hover {
  background-color: rgb(238, 238, 238);
}
.td-qualifications {
  padding: 8px 0;
}
.td-final-score {
  margin: 8px 0;
}
.red-score {
  background-color: rgb(225, 109, 109);
}
.yellow-score {
  background-color: rgb(245, 227, 122);
}
.green-score {
  background-color: rgb(119, 225, 109);
}
.green-score,
.yellow-score,
.red-score {
  padding: 3px 0;
}
.separator {
  margin-left: calc(100% - 2rem);
}
.btn-reload {
  background-color: var(--secondary-color);
  padding: 1px 3px;
  padding-top: 3px;
  justify-content: right;
  border-radius: 5px;
}
.reload-icon {
  color: #fff;
  transition: 0.3s;
  font-size: 22px;
}
.reload-icon:hover {
  transform: rotate(360deg);
  transition: 0.4s;
}
</style>